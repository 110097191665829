import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1092.000000 719.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,719.000000) scale(0.100000,-0.100000)">
       <path d="M3235 7164 c-486 -52 -899 -170 -1307 -375 -1069 -534 -1729 -1527
-1728 -2599 0 -240 18 -393 71 -620 82 -349 238 -687 453 -985 360 -500 874
-895 1466 -1130 395 -156 743 -235 1175 -266 258 -18 638 3 891 50 l99 18 -60
7 c-238 29 -461 78 -683 152 -118 38 -171 60 -362 146 -111 51 -331 183 -457
275 -71 52 -114 95 -59 59 52 -34 143 -86 167 -95 13 -5 49 -20 79 -33 30 -14
71 -31 90 -38 19 -7 39 -17 44 -22 6 -4 16 -8 24 -8 8 0 28 -6 45 -14 41 -18
308 -94 395 -112 37 -8 110 -24 162 -35 146 -32 428 -77 570 -92 25 -3 61 -8
80 -11 106 -18 461 -45 477 -36 10 5 63 26 118 46 55 20 151 61 214 90 62 30
117 54 121 54 5 0 10 3 12 8 2 4 26 18 53 32 28 14 85 46 128 72 43 27 82 48
87 48 5 0 10 4 12 8 2 4 30 26 63 47 89 59 155 107 183 132 13 12 49 41 80 65
59 45 67 53 177 158 l68 65 -504 3 c-534 3 -510 4 -592 -46 -74 -45 -315 -161
-367 -177 -14 -4 -38 -13 -55 -21 -16 -7 -77 -27 -135 -44 -58 -17 -125 -37
-150 -45 -88 -28 -331 -68 -525 -86 -164 -15 -526 -7 -660 16 -160 26 -358 66
-380 75 -11 5 -51 18 -90 29 -141 39 -481 190 -598 264 -31 20 -60 37 -63 37
-2 0 -46 28 -97 62 -1033 692 -1358 1944 -769 2968 145 252 392 532 627 711
376 287 878 497 1350 566 94 13 188 17 430 18 320 0 401 -6 600 -47 55 -11
105 -21 110 -22 6 -1 15 -4 20 -7 6 -4 28 -10 49 -13 48 -9 189 -52 233 -72
17 -8 34 -14 39 -14 8 0 122 -44 171 -66 17 -8 36 -14 41 -14 6 0 12 -3 14 -7
2 -5 52 -33 112 -63 60 -30 114 -59 120 -65 6 -5 33 -21 59 -35 27 -14 56 -32
65 -40 9 -9 33 -25 52 -37 19 -12 44 -30 56 -41 11 -11 30 -24 41 -31 26 -13
943 -16 943 -2 0 13 -266 280 -340 340 -36 30 -67 57 -70 60 -19 23 -311 231
-324 231 -2 0 -31 18 -64 40 -33 22 -64 40 -70 40 -6 0 -12 3 -14 8 -1 4 -23
17 -48 29 -25 12 -65 33 -90 47 -25 13 -81 41 -125 61 l-80 38 -195 -7 c-512
-18 -922 -53 -1215 -106 -220 -39 -262 -48 -404 -89 -79 -22 -150 -41 -157
-41 -7 0 -14 -4 -16 -8 -1 -5 -37 -21 -78 -37 -41 -15 -102 -44 -135 -63 -81
-47 -86 -49 -45 -13 46 41 235 154 380 226 329 165 730 276 1098 306 75 6 157
13 182 16 l45 6 -80 18 c-139 32 -374 68 -539 84 -195 18 -534 18 -711 -1z"/>
<path d="M2934 5849 c-639 -57 -1167 -454 -1387 -1044 -69 -186 -101 -386
-100 -635 0 -276 46 -572 119 -769 82 -222 199 -399 388 -586 244 -244 502
-380 846 -446 166 -33 479 -33 627 0 236 51 457 155 595 279 27 24 39 30 42
21 4 -13 26 -231 26 -257 0 -9 63 -12 250 -12 l250 0 2 508 3 507 163 3 162 2
0 275 c0 172 -4 276 -10 280 -5 3 -10 42 -10 86 l0 79 -795 0 -795 0 0 -274
c0 -173 4 -277 10 -281 6 -3 10 -42 10 -86 l0 -79 255 0 c140 0 255 -4 255 -8
0 -9 -64 -64 -130 -113 -63 -47 -177 -98 -277 -125 -120 -32 -334 -43 -464
-25 -100 15 -243 60 -299 96 -22 14 -43 25 -46 25 -17 0 -149 108 -207 169
-112 119 -186 250 -232 410 -26 90 -27 103 -22 245 8 226 56 390 161 551 190
289 494 430 891 412 166 -8 246 -28 383 -94 145 -72 279 -213 364 -385 l43
-88 268 0 c247 0 267 1 267 18 4 162 0 1111 -4 1122 -3 8 -7 49 -8 90 l-3 75
-275 0 -275 0 -5 -87 -5 -87 -45 30 c-236 160 -598 233 -986 198z m1541 -2942
l8 -238 -154 3 -153 3 -8 42 c-4 24 -8 105 -10 182 -2 108 0 137 10 134 6 -3
12 2 12 12 0 9 -4 14 -9 11 -5 -3 -12 1 -15 10 -8 22 -8 44 2 43 25 -4 40 4
45 22 4 16 11 20 27 16 16 -4 29 2 45 19 12 13 27 24 34 24 6 0 16 9 23 20 15
24 28 26 28 5 0 -8 5 -15 10 -15 6 0 10 7 10 15 0 21 18 19 23 -2 3 -10 5 15
6 55 0 40 5 72 11 72 6 0 10 -25 10 -56 0 -35 4 -53 10 -49 6 4 10 12 9 18 -1
7 0 32 1 57 4 69 17 -135 25 -403z m-353 181 c-7 -7 -12 -8 -12 -2 0 6 3 14 7
17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m-22 -19 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-68 -11 c-7 -7 -12 -8 -12 -2 0 6 3
14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m6 -57 c-18 -29 -38 -35 -38 -11 0
12 5 17 14 14 7 -3 16 3 20 12 4 9 9 14 12 11 3 -3 0 -14 -8 -26z m-70 -3 c-2
-6 -10 -14 -16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z m-3 -54 c14 -6 13 -10
-12 -31 -24 -21 -28 -22 -31 -8 -8 37 9 52 43 39z m-65 -23 c0 -5 -6 -13 -12
-18 -12 -8 -21 -9 -44 -4 -4 0 -1 8 6 16 13 16 50 21 50 6z m-137 -65 c4 -10
1 -13 -8 -9 -8 3 -12 9 -9 14 7 12 11 11 17 -5z m127 9 c0 -2 -31 -25 -70 -51
-49 -32 -75 -44 -90 -39 -14 5 -18 3 -15 -7 4 -10 -11 -22 -45 -37 -58 -26
-82 -21 -26 5 44 22 46 32 5 26 -21 -3 -30 -1 -25 6 3 6 40 14 82 17 49 5 71
11 63 16 -12 7 -11 11 1 19 8 5 23 10 34 10 13 0 16 5 12 16 -5 12 -2 15 13
11 10 -2 25 -1 32 4 15 10 29 12 29 4z m-275 -115 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-15 -50 c0 -5 -8 -10 -17 -10
-15 0 -16 2 -3 10 19 12 20 12 20 0z m-65 -20 c-28 -12 -55 -12 -55 0 0 6 17
10 38 9 30 0 33 -2 17 -9z m-85 -20 c-8 -5 -24 -9 -35 -9 -16 1 -16 2 5 9 35
11 48 11 30 0z m-60 -14 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15
10 8 0 15 -2 15 -4z"/>
<path d="M4220 3100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3930 2930 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6105 5854 c-291 -34 -491 -134 -668 -334 -172 -194 -245 -440 -218
-733 31 -325 78 -458 214 -604 178 -190 370 -277 972 -439 187 -51 351 -122
428 -185 20 -17 51 -51 68 -76 28 -39 31 -51 27 -97 -12 -146 -155 -290 -330
-331 -167 -39 -374 8 -515 117 -112 86 -208 239 -272 434 l-39 119 -269 0
-268 0 -3 -577 c-1 -384 1 -578 8 -578 6 0 10 -35 10 -85 l0 -85 275 0 275 0
0 95 c0 76 3 95 14 95 8 0 18 -4 21 -10 10 -16 105 -76 174 -110 155 -77 310
-116 502 -126 419 -21 754 143 948 465 132 219 170 550 104 911 -55 307 -214
497 -547 655 -150 72 -265 113 -512 185 -115 33 -247 72 -294 86 -165 50 -255
104 -294 176 -18 33 -18 37 -1 93 14 49 28 70 79 120 82 81 145 106 283 113
189 9 312 -34 423 -147 83 -85 131 -168 172 -298 l33 -103 257 0 258 0 -1 503
c-1 276 -4 545 -8 597 l-6 95 -265 0 -265 0 -3 -67 c-2 -38 -5 -68 -8 -68 -2
0 -26 15 -53 34 -98 67 -288 133 -446 156 -67 9 -195 11 -260 4z m1195 -744
l0 -240 -170 0 c-139 0 -170 3 -170 14 0 8 -11 46 -24 85 -24 71 -19 86 10 29
8 -16 13 -36 10 -44 -3 -9 -1 -12 4 -9 6 4 7 23 4 46 -6 34 -4 39 13 39 10 0
35 16 54 36 18 19 55 49 81 65 25 16 49 36 52 44 3 8 12 15 20 15 7 0 17 7 20
16 4 9 9 14 13 11 3 -4 11 -3 18 3 8 6 15 -2 25 -28 11 -32 14 -34 21 -17 4
11 8 55 8 98 1 42 3 77 6 77 3 0 5 -108 5 -240z m-390 -36 c0 -8 -4 -12 -10
-9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-810 -510 c0 -2 -7 -4
-15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m155 -45 c33 -7 71
-18 85 -25 14 -6 51 -15 83 -19 31 -4 57 -11 57 -16 0 -5 6 -9 14 -9 65 0 474
-153 570 -213 11 -7 47 -29 80 -50 118 -74 238 -214 279 -327 72 -200 75 -481
6 -683 -47 -135 -103 -223 -210 -327 -128 -125 -284 -200 -481 -231 -116 -18
-372 -7 -475 20 -189 49 -354 144 -488 280 -43 45 -82 81 -86 81 -5 0 -6 -69
-4 -152 3 -84 1 -159 -3 -166 -12 -18 -347 -13 -346 6 1 6 2 219 3 472 l1 460
179 0 179 0 7 -32 c12 -56 99 -240 145 -308 147 -216 330 -321 585 -336 185
-11 325 38 445 156 84 82 123 152 146 258 15 73 16 95 6 165 -14 93 -48 171
-101 231 -35 39 -205 156 -226 156 -6 0 -29 9 -51 19 -41 20 -235 81 -384 121
-47 12 -94 26 -105 31 -11 4 -31 11 -45 14 -114 29 -260 83 -260 96 0 4 15 11
33 15 17 4 26 9 20 11 -7 3 -27 0 -45 -6 -31 -10 -94 2 -114 21 -5 5 40 -3 71
-13 17 -5 55 12 79 36 24 23 17 38 -12 27 -11 -4 -11 -6 0 -13 10 -7 9 -9 -4
-9 -10 0 -18 7 -18 15 0 8 -4 15 -10 15 -5 0 -10 5 -10 11 0 7 7 8 18 4 31
-12 52 -14 52 -4 0 5 11 9 24 9 27 0 44 16 24 22 -8 3 -3 10 12 18 14 7 45 28
69 47 24 18 51 33 61 33 9 0 26 9 38 20 12 11 27 20 34 21 7 0 4 4 -7 8 -39
15 21 23 81 11 86 -18 74 -6 -16 16 -44 10 -80 22 -80 27 0 5 8 6 18 3 9 -2
44 -10 77 -17z m-560 -247 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12
-12z m131 -10 c-9 -9 -37 8 -31 18 5 8 11 7 21 -1 8 -6 12 -14 10 -17z m-76
-2 c-24 -15 -36 -11 -14 4 10 8 21 12 24 10 2 -3 -2 -9 -10 -14z"/>
<path d="M7760 5529 c0 -177 3 -268 10 -264 6 4 10 -25 10 -79 l0 -85 158 -3
157 -3 0 -995 0 -995 -167 -3 -168 -2 0 -265 c0 -170 4 -265 10 -265 6 0 10
-35 10 -85 l0 -85 1140 0 c1079 0 1140 1 1141 18 4 60 -2 894 -7 1020 l-6 152
-304 0 -304 0 0 -245 0 -245 -332 2 -333 3 -3 992 -2 993 192 2 193 3 3 259
c1 166 -1 263 -8 270 -5 5 -10 47 -10 93 l0 83 -690 0 -690 0 0 -271z m140
151 c0 -5 -4 -10 -10 -10 -5 0 -7 -7 -4 -15 4 -8 10 -12 15 -9 5 3 10 -10 11
-28 l1 -33 4 33 c3 24 11 36 31 44 l27 11 -30 7 c-16 4 223 8 533 9 l562 1 0
-165 0 -165 -190 0 -190 0 0 -1180 0 -1180 438 2 437 3 5 243 5 244 203 1 202
2 0 -412 0 -413 -1045 0 -1045 0 0 165 0 165 168 2 167 3 5 1177 c4 975 3
1179 -8 1183 -8 3 -83 5 -168 5 l-154 0 0 144 c0 79 3 151 6 160 7 18 24 22
24 6z"/>
<path d="M206 753 c-107 -37 -189 -149 -202 -275 -17 -165 57 -297 196 -347
53 -20 76 -23 144 -18 107 6 182 30 190 60 3 12 6 83 6 158 0 116 -2 138 -16
143 -9 3 -66 6 -129 6 l-113 0 -5 -26 c-10 -55 0 -64 69 -64 l64 0 0 -79 c0
-89 -2 -91 -89 -91 -65 0 -126 33 -156 85 -36 61 -44 156 -19 225 45 124 151
166 294 116 41 -14 80 -27 85 -28 6 -2 10 19 10 47 0 50 0 50 -48 74 -65 32
-208 39 -281 14z"/>
<path d="M1065 758 c-51 -18 -82 -46 -104 -93 -26 -56 -27 -97 0 -151 23 -49
60 -80 144 -118 79 -37 105 -62 105 -101 0 -44 -19 -65 -68 -76 -47 -10 -74
-6 -145 24 -54 22 -67 16 -67 -34 0 -37 21 -59 76 -80 84 -32 205 -17 269 33
54 43 80 146 54 215 -17 46 -60 83 -144 124 -87 43 -115 68 -115 105 0 62 90
84 179 44 24 -11 46 -18 49 -15 11 10 4 87 -9 99 -36 34 -159 47 -224 24z"/>
<path d="M1868 737 c-47 -24 -71 -45 -95 -81 -46 -68 -58 -117 -56 -231 2
-167 47 -250 161 -294 59 -23 139 -27 207 -10 42 11 44 10 97 -32 98 -77 172
-99 184 -54 12 48 -3 73 -61 101 -78 39 -81 45 -51 96 82 140 68 367 -29 463
-53 53 -115 75 -216 75 -68 0 -85 -4 -141 -33z m218 -88 c63 -30 86 -84 88
-202 1 -158 -50 -227 -167 -227 -73 0 -111 21 -137 75 -43 91 -34 250 18 314
49 60 125 76 198 40z"/>
<path d="M2729 748 c0 -7 0 -116 1 -243 1 -209 3 -234 21 -272 27 -56 68 -89
136 -109 110 -32 241 -1 301 72 44 54 53 118 50 354 l-3 205 -57 3 c-54 3 -58
2 -59 -20 -1 -13 -2 -27 -4 -33 -1 -5 -3 -102 -3 -213 l-2 -204 -34 -34 c-30
-30 -40 -34 -85 -34 -63 0 -98 16 -119 54 -14 26 -18 71 -22 256 l-5 225 -57
3 c-42 2 -58 -1 -59 -10z"/>
<path d="M3800 740 c-22 -41 -207 -595 -203 -607 6 -17 99 -17 110 -1 4 7 17
40 29 73 l21 60 119 3 119 3 25 -73 25 -73 58 -3 c44 -2 59 1 63 12 3 9 -41
152 -99 318 l-104 303 -76 3 c-68 3 -77 1 -87 -18z m125 -242 c22 -64 37 -119
35 -122 -7 -7 -159 -10 -166 -4 -3 3 14 63 36 133 23 70 45 123 49 119 4 -5
24 -62 46 -126z"/>
<path d="M4561 742 c-13 -25 -15 -590 -2 -610 5 -9 25 -12 62 -10 l54 3 3 128
3 127 37 0 c21 0 47 -7 59 -15 11 -8 40 -61 63 -117 24 -57 48 -109 53 -115
11 -15 127 -18 127 -4 0 5 -16 50 -36 101 -35 92 -79 170 -95 170 -22 0 -5 27
30 48 40 25 71 88 71 145 0 44 -37 105 -82 134 -40 27 -48 28 -189 32 -140 3
-148 2 -158 -17z m275 -103 c8 -9 18 -35 21 -57 5 -36 2 -45 -25 -72 -27 -27
-37 -30 -91 -30 l-61 0 0 88 c0 49 2 91 4 93 11 11 137 -8 152 -22z"/>
<path d="M5427 753 c-4 -3 -7 -145 -7 -314 0 -271 2 -308 16 -313 9 -3 87 -6
174 -6 147 0 160 2 170 19 5 11 7 33 4 50 l-6 31 -119 0 -119 0 0 90 c0 104
-3 102 113 97 83 -3 100 6 95 53 l-3 35 -102 3 -103 3 0 79 0 79 118 3 117 3
3 48 3 47 -174 0 c-95 0 -177 -3 -180 -7z"/>
<path d="M6207 753 c-4 -3 -7 -140 -7 -303 0 -222 3 -300 13 -313 11 -16 30
-17 167 -15 l155 3 0 50 0 50 -102 3 -103 3 0 249 c0 136 -3 255 -6 264 -5 12
-20 16 -58 16 -29 0 -56 -3 -59 -7z"/>
<path d="M7070 739 c-6 -11 -26 -66 -45 -122 -18 -56 -60 -182 -94 -280 -33
-98 -61 -187 -61 -198 0 -16 7 -19 49 -19 56 0 58 2 87 85 l21 60 120 3 120 3
24 -73 24 -73 63 -3 c55 -3 62 -1 62 16 0 15 -163 504 -199 595 -10 26 -13 27
-85 27 -66 0 -77 -3 -86 -21z m126 -239 l41 -129 -84 -3 c-47 -2 -87 -1 -88 3
-6 8 75 259 83 259 4 0 26 -59 48 -130z"/>
<path d="M7835 738 c-3 -13 -4 -156 -3 -318 l3 -295 50 0 50 0 5 260 c4 200 8
255 16 240 6 -11 46 -123 89 -250 43 -126 82 -236 88 -243 5 -6 31 -12 58 -12
38 0 51 4 59 20 6 11 40 105 76 208 36 103 75 214 87 247 l22 60 5 -265 5
-265 55 0 55 0 3 304 c2 216 -1 308 -9 317 -8 10 -35 14 -86 14 -93 0 -105
-11 -151 -142 -35 -96 -96 -258 -111 -293 -7 -16 -10 -17 -15 -5 -3 8 -37 103
-76 210 -44 125 -77 201 -91 213 -16 13 -40 17 -100 17 -73 0 -79 -2 -84 -22z"/>
<path d="M8945 748 c-6 -17 -8 -39 -6 -65 1 -22 5 -23 86 -23 l85 0 0 -254 c0
-140 3 -261 6 -270 4 -11 19 -16 49 -16 80 0 75 -19 75 276 l0 264 84 0 c81 0
84 1 88 25 3 14 3 36 0 50 l-4 25 -229 0 c-175 0 -231 -3 -234 -12z"/>
<path d="M9802 748 c-9 -9 -12 -91 -12 -308 0 -217 3 -299 12 -308 8 -8 64
-12 185 -12 l173 0 0 50 0 50 -120 0 -120 0 0 89 c0 89 0 89 28 95 15 4 54 6
87 5 33 -1 65 -1 71 0 15 1 25 39 18 68 -6 22 -10 23 -105 23 l-99 0 0 80 0
80 120 0 120 0 0 50 0 50 -173 0 c-121 0 -177 -4 -185 -12z"/>
<path d="M10587 754 c-4 -4 -7 -143 -7 -309 0 -246 3 -305 14 -314 9 -8 63
-11 167 -9 l154 3 3 44 c2 28 -1 47 -10 52 -7 5 -57 9 -110 9 l-98 0 -2 263
-3 262 -50 3 c-28 2 -54 0 -58 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
